<template>
  <div style="padding-bottom: 40px">
    <div class="main ssp">
      <div class="title">
        <el-row>
          <el-col :span="24">
            <span class="school_name" v-for="(item, i) in NavList" :class="{ hove: nav == i }" :key="i"
              @click="changeCollege(item, i)">{{ item.school_id }} &nbsp;{{ item.school_name }}</span>
          </el-col>
          <el-col :span="5" style="margin-top:10px">
            <el-row>
              <el-col :span="11">
                <el-select v-model="batch" placeholder="请选择批次" class="round_select" @change="onBatchChange()"
                  size="mini">
                  <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-col>
              <el-col :span="11">
                <el-select v-model="year" placeholder="选择年份" class="round_select" @change="onYearChange" size="mini">
                  <el-option v-for="item in years" :key="item" :label="item + '年'" :value="item" />
                </el-select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="3" :offset="16" style="margin-top:10px">
            <el-select v-model="orderStatus" placeholder="排序方式" class="round_select" @change="order" size="mini">
              <el-option :key="1" label="最低分升序" :value="1" />
              <el-option :key="-1" label="最低分降序" :value="-1" />
            </el-select>
          </el-col>
        </el-row>
      </div>

      <div class="table">
        <el-row class="header">
          <el-col :span=2>年份</el-col>
          <el-col :span=8>专业名称</el-col>
          <el-col :span=2>招生批次</el-col>
          <el-col :span=2>最高分</el-col>
          <el-col :span=2>最高位次</el-col>
          <el-col :span=2>平均分</el-col>
          <el-col :span=2>最低分
            <!-- <i class="el-icon-d-caret" @click="order"> </i> -->
          </el-col>
          <el-col :span=2>最低位次</el-col>
          <el-col :span=2>计划数</el-col>
        </el-row>
        <template v-if="tableData">
          <div class="tab-row5">
            <el-row v-for="(item, i) in tableData" :key="i">
              <el-col :span=2 class="tab-row">{{ item.year }}</el-col>
              <el-col :span=8 class="tab-row">
                {{ item.specialty_name }}
              </el-col>
              <el-col :span=2 class="tab-row">
                <div v-for="i in batchList" :key="i.id">
                  <span v-if="item.batch == i.id">
                    {{ i.name }}
                    <span v-if="item.batch == 4">/{{ item.pici }}</span>
                  </span>
                </div>
              </el-col>
              <el-col :span=2 class="tab-row">{{ item.max_score | changeZero }} &nbsp;</el-col>
              <el-col :span=2 class="tab-row">{{ item.max_ranking | changeZero }}</el-col>
              <el-col :span=2 class="tab-row">{{ item.mean_score | changeZero }}</el-col>
              <el-col :span=2 class="tab-row">{{ item.min_score | changeZero }}</el-col>
              <el-col :span=2 class="tab-row">{{ item.ranking | changeZero }}</el-col>
              <el-col :span=2 class="tab-row tab-row2">{{ item.plan_num | changeZero }}</el-col>
            </el-row>
          </div>
        </template>
        <el-row v-else class="tab-row">
          <el-col :offset="10" :span="4">暂无数据！</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SpecialtyLine",
  computed: { ...mapState(["userInfo"]) },
  data() {
    return {
      school_id: "",
      tableData: [],
      is_wenli: "1",
      batch: "1",
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "1" },
        { id: "3", name: "高职高专批", code: "3", status: "1" },
        { id: "4", name: "其他批次", code: "4", status: "1" },
      ],
      year: "2024", // 年份
      years: [
        "2024",
        "2023",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
      ],
      nav: 0,
      NavList: [],
      select_code: "",
      num: 1,
      orderStatus: 1
    };
  },
  mounted() {
    this.school_id = this.$route.query.sid;
    if (this.school_id == "") {
      return false;
    }
    this.is_wenli = this.userInfo.is_wenli;
    this.getNav();
  },
  methods: {
    getNav() {
      if (!this.school_id) {
        return;
      }
      this.scoreLine = [];
      this.$fecth
        .get("show_school_score/getNav", {
          params: {
            batch: this.batch,
            is_wenli: this.is_wenli,
            school_id: this.school_id,
            child_school_id: this.child_school_id,
            type: 1
          },
        })
        .then((res) => {
          if (res.length > 0) {
            this.NavList = res;
            if (this.NavList[0]) {
              this.select_code = this.NavList[0].select_code;
              this.batch = String(this.NavList[0].batch);
            }
            this.getScoreLine();
          }
        });
    },
    onBatchChange() {
      this.getScoreLine();
    },
    onYearChange() {
      this.getScoreLine();
    },
    getScoreLine() {
      if (this.select_code == "") {
        return false;
      }
      this.$fecth
        .post("show_school_score/getMajorData", {
          pageSize: 100,
          year: this.year,
          batch: this.batch,
          is_wenli: this.is_wenli,
          select_code: this.select_code,
        })
        .then((res) => {
          this.num++;
          if (this.year == 2024 && this.num == 2 && res.list.length == []) {
            this.year = "2023";
            this.getScoreLine();
            return;
          }

          this.tableData = res.list;
        });
    },

    changeCollege(item, i) {
      this.nav = i;
      this.select_code = item.select_code;
      this.batch = String(item.batch);
      this.getScoreLine();
    },
    order() {
      let that = this
      this.tableData = this.tableData.sort(function (a, b) {
        if (that.orderStatus == 1) {
          return a["min_score"] - b['min_score']
        } else {
          return b["min_score"] - a['min_score']
        }
      })
    }
  },
};
</script>
<style lang="less">
.bgCard {
  background: none;
}
</style>

<style lang="less" scoped>
.main {
  width: 1200px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;

  .title {
    margin-top: 20px;

    .school_name {
      display: inline-block;
      border: 1px solid #c9c5c540;
      height: 20px;
      line-height: 20px;
      padding: 10px 12px;
      font-size: 16px;
      margin-bottom: 2px;
    }
  }

  .hove {
    background: #519deb;
    color: white;
    border: 1px #519deb solid !important;
  }

  .table {
    margin: 7px 0;

    .header {
      background: #f4f7fc;
      color: black;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      text-align: center;
    }

    .tab-row {
      border: 1px solid #f4f7fc;
      border-right: 0;
      min-height: 36px;
      line-height: 36px;
      border-bottom: 0;
      text-align: center;
    }

    .tab-row {
      border-right: 1px solid #f4f7fc;
    }

    .tab-row5 {
      background-image: url('../../assets/logo1.png');
    }

    .tab-row4:last-child {
      border-bottom: 1px solid #f4f7fc;

    }
  }
}
</style>
<style lang="less">
.round_select {
  .el-input__inner {
    text-align: center;
    font-size: 16px;
    border-radius: 30px;
    height: 23px;
    line-height: 23px;
  }

  .el-input--mini .el-input__icon {
    line-height: 23px;
  }
}
</style>